body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    
    font-weight: 400;
    font-style: normal;
  }

  


/* 
.bg-img{
    backgroundImage: url('./images/gymBgImg.jpg');
    backgroundSize: 'cover';
    backgroundPosition: 'center';
    backgroundRepeat: 'no-repeat';
    minHeight: '100vh';
    width: '100%';
    display: 'flex';
    flexDirection: 'column';
    justifyContent: 'center';
    alignItems: 'center';
} */



.bground-img {
    background-image: url('./images/gymBgImg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -80px;
    color: white;
    /* background-color: aqua; */
    
  }

  .text-3xl {
    font-size: 3.7rem; /* Increase font size for the main heading */
    font-family: "Baskervville SC", serif;
  }
  
  .second-text{
    font-size: 1.7rem; /* Increase font size for the paragraph */
    font-family: "Baskervville SC", serif;
    
  }
  
  button {
    font-size: 1.2rem; /* Increase font size for the button */
  }
  

  