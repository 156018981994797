footer {
    width: 100%;
    position: relative;
    bottom: 0;


    
    
    
  }
  
  .bg-dark {
    background-color: #3f3e3e !important;
  }
  
  .text-white {
    color: #fff !important;
  }
  
  .pt-4 {
    padding-top: 1.5rem !important;
  }
  
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  